var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.created ? _c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.saveBtnLoading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'next');
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.saveBtnLoading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, 'previous');
      }
    }
  }, [_vm._v(" Save & close ")])], 1), _c('v-card-text', {
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-input', {
    attrs: {
      "row": "",
      "type": "radio",
      "items": _vm.VENUE_TYPES,
      "item-value": "text",
      "name": "venueType",
      "title": "Venue Type",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeVenueType();
      }
    },
    model: {
      value: _vm.content.venueType,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "venueType", $$v);
      },
      expression: "content.venueType"
    }
  })], 1), _vm.content.venueType === _vm.VENUE_TYPE.SPACE ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "item-text": function itemText(item) {
        return "".concat(item.name, " (").concat(item.status, ")");
      },
      "item-value": "_id",
      "items": _vm.spaces,
      "name": "Space",
      "title": "Select space *",
      "loading": _vm.spacesLoader,
      "required": _vm.content.venueType === _vm.VENUE_TYPE.SPACE
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeSpace();
      }
    },
    model: {
      value: _vm.content.spaceId,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "spaceId", $$v);
      },
      expression: "content.spaceId"
    }
  })], 1) : _vm._e(), _vm.content.venueType === _vm.VENUE_TYPE.LOCATION ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "item-text": function itemText(item) {
        return "".concat(item.name, " (").concat(item.status, ")");
      },
      "item-value": "_id",
      "items": _vm.locations,
      "name": "Location",
      "title": "Select location *",
      "loading": _vm.locationLoader,
      "required": _vm.content.venueType === _vm.VENUE_TYPE.LOCATION
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeLocation();
      }
    },
    model: {
      value: _vm.content.locationId,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "locationId", $$v);
      },
      expression: "content.locationId"
    }
  })], 1) : _vm._e(), _vm.content.venueType === _vm.VENUE_TYPE.SPACE ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "title": "Space name (override)",
      "type": "text",
      "name": "spaceName",
      "placeholder": _vm.spaceData.spaceName || 'Space name'
    },
    model: {
      value: _vm.content.spaceName,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "spaceName", $$v);
      },
      expression: "content.spaceName"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('vx-input', {
    staticClass: "mr-2 w-100",
    attrs: {
      "title": "Location name (override)",
      "type": "text",
      "placeholder": _vm.spaceData.locationName || 'Location name',
      "name": "locationName"
    },
    model: {
      value: _vm.content.locationName,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "locationName", $$v);
      },
      expression: "content.locationName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('vx-input', {
    staticClass: "mr-2 w-100",
    attrs: {
      "title": "Account name (override)",
      "type": "text",
      "placeholder": _vm.spaceData.accountName || 'Account name',
      "name": "accountName"
    },
    model: {
      value: _vm.content.accountName,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "accountName", $$v);
      },
      expression: "content.accountName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('vx-input', {
    staticClass: "mr-2 w-100",
    attrs: {
      "title": "Nearest place (override)",
      "type": "text",
      "placeholder": _vm.spaceData.nearestPlace || 'Nearest place'
    },
    model: {
      value: _vm.content.nearestPlace,
      callback: function callback($$v) {
        _vm.$set(_vm.content, "nearestPlace", $$v);
      },
      expression: "content.nearestPlace"
    }
  })], 1), _c('ContentUrlField', {
    attrs: {
      "content": _vm.content
    }
  })], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }