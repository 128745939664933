<template>
  <v-form ref="form" lazy-validation>
    <v-card v-if="created">
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn
          width="100"
          outlined
          color="light"
          class="ml-2"
          :loading="saveBtnLoading"
          @click="$validate(save, 'next')"
        >
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="saveBtnLoading" @click="$validate(save, 'previous')">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12">
            <vx-input
              v-model="content.venueType"
              row
              type="radio"
              :items="VENUE_TYPES"
              item-value="text"
              name="venueType"
              title="Venue Type"
              required
              @change="onChangeVenueType()"
            />
          </v-col>
          <v-col v-if="content.venueType === VENUE_TYPE.SPACE" cols="6">
            <vx-input
              v-model="content.spaceId"
              type="autocomplete"
              :item-text="item => `${item.name} (${item.status})`"
              item-value="_id"
              :items="spaces"
              name="Space"
              title="Select space *"
              :loading="spacesLoader"
              :required="content.venueType === VENUE_TYPE.SPACE"
              @change="onChangeSpace()"
            />
          </v-col>
          <v-col v-if="content.venueType === VENUE_TYPE.LOCATION" cols="6">
            <vx-input
              v-model="content.locationId"
              type="autocomplete"
              :item-text="item => `${item.name} (${item.status})`"
              item-value="_id"
              :items="locations"
              name="Location"
              title="Select location *"
              :loading="locationLoader"
              :required="content.venueType === VENUE_TYPE.LOCATION"
              @change="onChangeLocation()"
            />
          </v-col>

          <v-col v-if="content.venueType === VENUE_TYPE.SPACE" cols="6">
            <vx-input
              v-model="content.spaceName"
              title="Space name (override)"
              type="text"
              name="spaceName"
              :placeholder="spaceData.spaceName || 'Space name'"
            />
          </v-col>
          <v-col cols="6">
            <vx-input
              v-model="content.locationName"
              title="Location name (override)"
              class="mr-2 w-100"
              type="text"
              :placeholder="spaceData.locationName || 'Location name'"
              name="locationName"
            />
          </v-col>
          <v-col cols="6">
            <vx-input
              v-model="content.accountName"
              title="Account name (override)"
              class="mr-2 w-100"
              type="text"
              :placeholder="spaceData.accountName || 'Account name'"
              name="accountName"
            />
          </v-col>
          <v-col cols="6"
            ><vx-input
              v-model="content.nearestPlace"
              title="Nearest place (override)"
              class="mr-2 w-100"
              type="text"
              :placeholder="spaceData.nearestPlace || 'Nearest place'"
            />
          </v-col>
          <!-- <v-col cols="12" sm="2" class="d-flex align-center">
            <vx-input v-model="content.system" type="checkbox" label="Is System?" />
          </v-col>
          <v-col cols="12" sm="2" class="d-flex align-center">
            <vx-input v-model="content.isVerified" type="checkbox" label="Is Verified?" />
          </v-col> -->
          <ContentUrlField :content="content"></ContentUrlField>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { Content } from '@tutti/models';
import { ContentService, SpaceService, LocationService } from '@tutti/services';
import { YES_NO_ITEMS, VENUE_STATUS, VENUE_TYPE, VENUE_TYPES } from '@tutti/constants';

export default {
  name: 'ContentPageEdit',
  components: {
    ContentUrlField: () => import('@admin/views/content/components/ContentUrlField'),
  },

  data() {
    return {
      saveBtnLoading: false,
      content: new Content({
        isVerified: true,
        system: true,
        venueType: VENUE_TYPE.SPACE,
      }),
      YES_NO_ITEMS,
      VENUE_TYPES,
      VENUE_TYPE,
      spaces: [],
      locations: [],
      spacesLoader: false,
      locationLoader: false,
      spaceData: {},
      created: false,
    };
  },
  async created() {
    await this.getContent();
    await this.getSpaceList();
    await this.getLocationList();

    this.onChangeVenueType();
    this.created = true;
  },
  methods: {
    async getContent() {
      if (this.$route.params.id) {
        const response = await ContentService.getById(this.$route.params.id);
        if (response) {
          this.content = new Content(response.data);
        }
      }
    },

    async getSpaceList() {
      this.spacesLoader = true;
      const response = await SpaceService.getSpaces({
        select: 'name status',
        filter: { status: [VENUE_STATUS.OPEN, VENUE_STATUS.CLOSED] },
        populate: { locationId: 'name nearestPlace', accountId: 'name' },
      });
      if (response) {
        this.spaces = response.data.data;
      }
      this.spacesLoader = false;
    },

    async getLocationList() {
      this.locationLoader = true;
      const response = await LocationService.getLocations({
        select: 'name status nearestPlace',
        filter: { status: [VENUE_STATUS.OPEN, VENUE_STATUS.CLOSED] },
        populate: { accountId: 'name' },
      });
      if (response) {
        this.locations = response.data.data;
      }
      this.locationLoader = false;
    },

    onChangeVenueType() {
      if (this.content.venueType === VENUE_TYPE.SPACE) {
        this.onChangeSpace();
      } else if (this.content.venueType === VENUE_TYPE.LOCATION) {
        this.onChangeLocation();
      }
    },

    onChangeSpace() {
      const space = this.spaces.find(space => space._id === this.content.spaceId);
      if (space) {
        this.spaceData.spaceName = space.name;
        this.spaceData.accountName = space.accountId?.name;
        this.spaceData.locationName = space.locationId?.name;
        this.spaceData.nearestPlace = space.locationId?.nearestPlace;
      }
    },

    onChangeLocation() {
      const location = this.locations.find(location => location._id === this.content.locationId);
      if (location) {
        this.spaceData.locationName = location.name;
        this.spaceData.nearestPlace = location.nearestPlace;
        this.spaceData.accountName = location.accountId?.name;
      }
    },

    async save(action) {
      this.saveBtnLoading = true;
      let content = this.content.get();
      content.sort = 0;
      const response = await ContentService.save(content);
      if (response) {
        if (action === 'previous') {
          this.$previous();
        } else if (response.data?._id) {
          this.$replace(this.$toEdit('content', response.data?._id));
        }
      }
      this.saveBtnLoading = false;
    },
  },
};
</script>
